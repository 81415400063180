import {Card, Col, Row, Tooltip, Typography} from "antd";
import {InfoCircleFilled} from "@ant-design/icons";
import {abbreviateNumber} from "../shared/Utils";
import React from "react";

const {Title, Paragraph, Text} = Typography;

type Props = {
    incoming: {
        eventNamesCount: number,
        eventsCount: number
    },
    outgoing: {
        eventNamesCount: number,
        eventsCount: number
        publishSuccessRate: number
    }
}

export const EventActivityTrafficComponent = ({incoming, outgoing}: Props) => {
    const cardStyle = {height: '180px', backgroundColor: '#f6f6f6', boxShadow: '0 0 0 0'};
    return (
        <Row gutter={[20, 0]}>
            <Col flex="275px">
                <Title level={5} style={{margin: 0}}>
                    Success Rate{' '}
                    <Tooltip
                        title={
                            'The percentage only accounts for publishing errors' +
                            " and doesn't include processing errors or events disabled from publishing."
                        }
                    >
                        <InfoCircleFilled/>
                    </Tooltip>
                </Title>
                <Paragraph>Traffic successfully published</Paragraph>
                <Card style={cardStyle}>
                    <Title level={2}>
                        {outgoing.eventsCount === 0
                            ? '-'
                            : (outgoing.publishSuccessRate * 100).toPrecision(3) + '%'}
                    </Title>
                    <Text type={'secondary'}>
                        The percentage of processed events successfully published to the
                        Conversions API.
                    </Text>
                </Card>
            </Col>
            <Col flex="auto">
                <Title level={5} style={{margin: 0}}>
                    Incoming
                </Title>
                <Paragraph>Traffic received from browser Pixels</Paragraph>
                <Card style={cardStyle}>
                    <Title level={5}>Event Types</Title> {incoming.eventNamesCount}
                    <Title level={5}>Event Count</Title>{' '}
                    {abbreviateNumber(incoming.eventsCount)}
                </Card>
            </Col>
            <Col flex="auto">
                <Title level={5} style={{margin: 0}}>
                    {' '}
                    Outgoing{' '}
                    <Tooltip
                        title={
                            'The outgoing count may be less than the incoming count due to processing and publishing errors' +
                            ' or events that were disabled from publishing.'
                        }
                    >
                        <InfoCircleFilled/>
                    </Tooltip>
                </Title>
                <Paragraph>Traffic published to Conversions API</Paragraph>
                <Card style={cardStyle}>
                    <Title level={5}>Event Types</Title> {outgoing.eventNamesCount}
                    <Title level={5}>Event Count</Title>{' '}
                    {abbreviateNumber(outgoing.eventsCount)}
                </Card>
            </Col>
        </Row>
    );
}
