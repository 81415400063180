/**
 * @format
 */
import React from 'react';
import {Card, Space, Typography} from 'antd';

const {Text, Title, Paragraph} = Typography;

interface Props {
  domains: readonly string[];
}

export function DomainListCard(props: Props) {
  const {domains} = props;

  const cardDescription =
    domains.length == 0
      ? 'There are no domains currently configured.'
      : 'Conversions API Gateway is configured to receive events from the following domains.';

  return (
    <Card>
      <Title level={5}>Connected Domains</Title>
      <Paragraph ellipsis>{cardDescription}</Paragraph>
      <Space direction="vertical" align={'start'} size="middle">
        {domains.map(domain => (
          <Text strong>{domain}</Text>
        ))}
      </Space>
    </Card>
  );
}
