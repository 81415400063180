import React, { useContext, useState } from 'react';

const useSelectedPixelIdState = ((initialState: string | null) => useState<string | null>(initialState))
type SelectedPixelIdContextType = ReturnType<typeof useSelectedPixelIdState>
const SelectedPixelIdContext = React.createContext<SelectedPixelIdContextType | undefined>(undefined);

export const SelectedPixelIdProvider: React.FC = ({ children }) => {
    const contextValue = useSelectedPixelIdState(null);
    return <SelectedPixelIdContext.Provider value={contextValue}>{children}</SelectedPixelIdContext.Provider>;
}

const useContextValue = (): SelectedPixelIdContextType => {
    const contextValue = useContext(SelectedPixelIdContext);
    if (contextValue === undefined) {
        throw new Error("use[Set]SelectedPixelId() must be used only inside <SelectedPixelIdProvider>");
    }
    return contextValue;
}

export const useSelectedPixelId = () => useContextValue()[0];
export const useSetSelectedPixelId = () => useContextValue()[1];
