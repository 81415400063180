/**
 * @format
 */
import React from 'react';
import {Alert, Button, Card, Form, Input, Space, Typography} from 'antd';

import styles from './App.module.less';
import {SingleColumnLayout} from '@cloudbridge/components';

type FormState = {
  username: string;
  password: string;
};
function LoginForm() {
  const [error, setError] = React.useState<string | undefined>(undefined);

  const onFinish = async (values: FormState) => {
    try {
      const response = await fetch('/auth/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        // We are now logged in. Reloading the page will make the backend redirect to the target
        window.location.reload();
      } else if (response.status >= 400 && response.status < 500) {
        const body = await response.json();
        const message = body.message || 'Unauthorised';
        setError(message);
      } else if (response.status >= 500 && response.status < 600) {
        setError('The service is not reachable at the moment');
      } else {
        setError('Unexpected response from the server');
      }
    } catch {
      setError('The service is not reachable at the moment');
    }
  };

  return (
    <Form<FormState> size={'large'} name="login" onFinish={onFinish}>
      <Form.Item
        name="username"
        rules={[
          {required: true, message: 'Please input your Admin Account Name!'},
        ]}>
        <Input placeholder="Admin Account Name" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{required: true, message: 'Please input your Password!'}]}>
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" block={true} htmlType="submit">
          Log in
        </Button>
      </Form.Item>
      {error && <Alert message="Error" description={error} type="error" />}
    </Form>
  );
}

function LoginCard() {
  return (
    <Card className={styles.login_card}>
      <div className={styles.login_card_row}>
        <Space
          className={styles.login_card_column}
          direction="vertical"
          size="large">
          <Typography.Title level={4}>Log In</Typography.Title>
          <LoginForm />
        </Space>
      </div>
    </Card>
  );
}

export default function Home() {
  return (
    <SingleColumnLayout title="Conversions API Gateway">
      <LoginCard />
    </SingleColumnLayout>
  );
}
