import React from "react";
import {Select, Tooltip, Space, Typography} from 'antd';
import {WarningFilled} from "@ant-design/icons";

const {Option} = Select;

const genDisabledOption = (text: String) => {
  return (
    <Space direction="horizontal">
      <Tooltip title={"Only data from the past hour is available at the moment." +
      " This doesn't affect the processing and publishing of events."}>
        <WarningFilled style={{color: 'orange'}}/>
      </Tooltip>
      <Typography.Text style={{color: "gray"}}>{text}</Typography.Text>
    </Space>
  )
}

export const timeWindowOptions = [
  <Option value={'30d'} key={0} disabled={true}>
    {genDisabledOption("Last 30 Days")}
  </Option>,
  <Option value={'7d'} key={1} disabled={true}>
    {genDisabledOption("Last 7 Days")}
  </Option>,
  <Option value={'24h'} key={2} disabled={true}>
    {genDisabledOption("Last 24 Hours")}
  </Option>,
  <Option value={'1h'} key={3}>
    Last Hour
  </Option>,
];